const config = {
  localDevelopment: {
    apiDomain: 'localhost',
  },

  localhost: {
    apiDomain: 'devamoustms.com',
  },

  development: {
    apiDomain: 'devamoustms.com',
  },

  // NOTE: temporary call
  // testing: {
  //   apiDomain: 'devamoustms.com',
  // },

  testing: {
    apiDomain: 'devamoustms.com',
  },

  staging: {
    apiDomain: 'testamoustms.com',
  },

  production: {
    apiDomain: 'amoustms.com',
  },
};

module.exports = config[process.env.API_DOMAIN];
