// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./helvetica-neue-medium-594a96118f07f.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./helvetica-neue-594a964f578e4.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"HelveticaNeue Medium\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"HelveticaNeue Regular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"HelveticaNeue Regular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n* {\n  font-family: \"Arial\";\n}", "",{"version":3,"sources":["webpack://./fonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,mCAAA;EACA,+DAAA;AACF;AAEA;EACE,oCAAA;EACA,+DAAA;AAAF;AAGA;EACE,oCAAA;EACA,+DAAA;AADF;AAIA;EACE,oBAAA;AAFF","sourcesContent":["@font-face {\n  font-family: \"HelveticaNeue Medium\";\n  src: url(\"./helvetica-neue-medium-594a96118f07f.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"HelveticaNeue Regular\";\n  src: url(\"./helvetica-neue-594a964f578e4.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"HelveticaNeue Regular\";\n  src: url(\"./helvetica-neue-594a964f578e4.ttf\") format(\"truetype\");\n}\n* {\n  font-family: \"Arial\";\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
